import { inject } from 'aurelia-framework';
import { Client } from './api/client';
import { FlashService } from './flash/flash-service';
import 'gridstack';
import 'gridstack/dist/gridstack.css';
import { AureliaConfiguration } from 'aurelia-configuration';
import { DialogService } from 'aurelia-dialog';
import { render, unmountComponentAtNode } from 'react-dom';
import { ConfigurationLoader } from './form/loader/configuration-loader';
import { UrlUtils } from './utilities/url-utils';
import { SubmitHandler } from './form/submit/submit-handler';
import { AuthTokenStorage } from './auth/auth-token-storage';
import React from 'react';
import { I18N } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';
import Dashboard from './dashboard/Dashboard';
import DashboardProvider from './dashboard/components/DashboardStore';
import { LicenseInfo } from '@mui/x-license';
import './dashboard/assets/dashboard-styles.less';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import store from './store/store';
import { Provider } from 'react-redux';
import { MUI_TABLE_KEY } from './table/MuiTable/Table';
@inject(Element)
export class Welcome {
    object = { viewId: 'order/detail' };

    attached() {
        this.container = document.getElementById('sio-dashboard');
        this.reactRender();
    }

    reactRender() {
        if (this.container) {
            LicenseInfo.setLicenseKey(MUI_TABLE_KEY);
            render(
                <Provider store={store}>
                    <DashboardProvider value={{}}>
                        <Dashboard />
                    </DashboardProvider>
                </Provider>,
                this.container
            );
        }
    }

    detached() {
        unmountComponentAtNode(this.container);
    }

    activate() {
        $(function () {
            var options = {
                cellHeight: 80,
                verticalMargin: 10,
                horizontalMargin: 10,
            };
            $('.grid-stack').gridstack(options);
        });
    }
}
